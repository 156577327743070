@font-face {
		font-family: ascii;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/ascii/ascii.eot');
		src: local(ascii), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/ascii/ascii.ttf) format('opentype');
}
@font-face {
		font-family: bebasneue_thin;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_thin.ttf');
		src: local(bebasneue_thin), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_thin.ttf) format('opentype');
}
@font-face {
		font-family: bebasneue_regular;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_regular.ttf');
		src: local(bebasneue_regular), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_regular.ttf) format('opentype');
}
@font-face {
		font-family: bebasneue_light;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_light.ttf');
		src: local(bebasneue_light), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_light.ttf) format('opentype');
}
@font-face {
		font-family: bebasneue_book;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_book.ttf');
		src: local(bebasneue_book), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_book.ttf) format('opentype');
}
@font-face {
		font-family: bebasneue_bold;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_bold.ttf');
		src: local(bebasneue_bold), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/bebasneue/bebasneue_bold.ttf) format('opentype');
}
@font-face {
		font-family: Segoe;
		src: url('https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/segoe-ui/segoeuil.ttf');
		src: local(Segoe UI), url(https://s3-us-gov-west-1.amazonaws.com/static.defcad.com/fonts/segoe-ui/segoeuil.ttf) format('truetype');
}

html {
		height: 100%;
}

body {
    height: 100%;
    background: radial-gradient(circle at top left, #29b6f6, #aa00ff);
}

div.body-wrapper {
    background-image: url(images/graph_paper.png);
    width: 100%;
    height: 100%;
}

.transaction-container {
    padding: 100px 0;
}

.transaction-title {
    font-family: bebasneue_bold;
    letter-spacing: 1px;
    padding-top: 10px;
    display: block;
    text-align: center;
    font-size: 28px;
}

.transaction-form {
    background: #fafafa;
    padding: 110px 50px 50px 50px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .3);
    border-radius: 10px;
    position: relative;
}

.transaction-form input,
.transaction-form select {
    font-family: bebasneue_bold !important;
    letter-spacing: 1px;
    font-size: 20px;
}

.transaction-form ::placeholder {
    color: #aaa;
}

.transaction-profile-container {
    position: absolute;
    top: -53px;
    z-index: 2;
    left: 0;
    width: 100%;
}

.transaction-profile {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #000;
    margin: 0 auto;
    background-position: center;
    /*background-image: url("images/ddlogo.png");
    background-size: cover;
    border: none;*/
    /*background-image: url("images/jumbotron_glock.svg");
    background-size: 107%;
    border: 3px solid #29b6f6;*/
}

.transaction-form label {
    font-weight: bold;
}

.field-space {
    margin-bottom: 20px;
}

.transaction-powered-msg-line {
    border-top: 1px solid #ddd;
    position: relative;
    margin-top: 20px;
}

.transaction-powered-msg-container {
    position: absolute;
    top: -13px;
    text-align: center;
    width: 100%;
}

.transaction-powered-msg {
    text-align: center;
    margin: 0;
    padding: 0 10px;
    font-family: bebasneue_bold;
    letter-spacing: 1px;
    background: #fafafa;
    display: inline;
}

.transaction-powered-msg a {
    color: #aa00ff;
    text-decoration: none;
}

.transaction-powered-msg a:hover {
    color: #aa00ff;
    text-decoration: none;
    cursor: pointer;
}

.transaction-next-btn {
    font-family: bebasneue_bold;
    width: 100%;
    text-transform: uppercase;
    color: white;
    font-size: 15px;
    background: #29b6f6;
    margin: 0px 0 20px 0;
    padding: 15px;
    display: inline-block;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 0;
    transition: all ease 0.4s;
    cursor: pointer;
    box-shadow: inset 0 0 0 0.01px #29b6f6;
}

.transaction-next-btn:hover {
    box-shadow: inset 400px 0 0 0 #aa00ff;
}

.transaction-redirect-wrapper {
  display: none;
  text-align: center;
}

.transaction-redirect-countdown {
    text-align: center;
    font-family: bebasneue_bold;
    font-size: 18px;
    margin-bottom: 10px;
}

.transaction-redirect-button-link {
		text-align: center;
    display: block;
}

.transaction-redirect-button {
    font-family: bebasneue_bold;
    text-transform: uppercase;
    color: white;
    padding: 15px;
    display: inline-block;
    border-radius: 4px;
    letter-spacing: 1px;
    border: 0;
    transition: all ease 0.4s;
    cursor: pointer;
    width: 200px;
    margin: 0px auto 35px 0px;
    background-color: #000;
    font-size: 18px;
}

.transaction-form-step-2,
.transaction-form-step-3,
.transaction-form-success,
.transaction-form-error {
    display: none;
}

.page-transaction .top_content {
    height: auto;
    padding-bottom: 100px;
}

.transaction-sent-msg {
    margin: 15px 0 35px 0;
    font-size: 20px;
    text-align: center;
    font-family: bebasneue_bold;
}

.transaction-error-msg {
    margin: 15px 0 35px 0;
    font-size: 20px;
    text-align: center;
    font-family: bebasneue_bold;
    color: red;
}

.checkmark {
    margin: 0 auto;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #aa00ff;
    animation: fill-success .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #aa00ff;
    fill: none;
    animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
}

.xbox {
    margin: 0px auto;
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px red;
    animation: fill-error .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
}

.xbox .circle {
    stroke: red;
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    fill: none;
    animation: stroke .6s cubic-bezier(0.650, 0.000, 0.450, 1.000) forwards;
}

.xbox .line {
    animation: stroke .3s cubic-bezier(0.650, 0.000, 0.450, 1.000) .8s forwards;
    stroke-width: 4;
    stroke-dashoffset: 1000;
    stroke: #fff;
    margin: 0 auto;
}

p {
  text-align: center;
  margin: 20px 0 60px;
  font-size: 1.25em;
  &.success {
    color: #73AF55;
  }
  &.error {
    color: #D06079;
  }
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%,
    100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill-error {
    100% {
        box-shadow: inset 0px 0px 0px 30px red;
    }
}

@keyframes fill-success {
    100% {
        box-shadow: inset 0px 0px 0px 30px #aa00ff;
    }
}

@media (max-width: 767.98px) {
    .transaction-container {
        padding: 100px 20px;
    }
}

#cover-spin {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color: rgba(255,255,255,0.7);
    z-index:9999;
    display:none;
}

@-webkit-keyframes spin {
	from {-webkit-transform:rotate(0deg);}
	to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
	from {transform:rotate(0deg);}
	to {transform:rotate(360deg);}
}

#cover-spin::after {
    content:'';
    display:block;
    position:absolute;
    left:48%;top:40%;
    width:40px;height:40px;
    border-style:solid;
    border-color:black;
    border-top-color:transparent;
    border-width: 4px;
    border-radius:50%;
    -webkit-animation: spin .8s linear infinite;
    animation: spin .8s linear infinite;
}

div.errortext {
    font-size: 12px;
    color: red;
    margin-left: 49px;
    margin-top: 5px;
}

button:disabled {
  background: grey !important;
  transition: none;
  box-shadow: none !important;
}
